import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blogLayout.js";
import Image from '../../components/blogImage.js';
export const pageQuery = graphql`
  query {
    mdx(slug: { eq: "blog/chatbots-what-why-how-pt2" }) {
      frontmatter {
        date
        description
        tags
        title
        author
        image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    authorPhoto: file(relativePath: { eq: "team/rick.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Image src="blog/chatbots-what-why-how-pt2/header.png" alt="Image of a chatbot app" mdxType="Image" />
    <p>{`Welcome back to the second part of our chatbot mini-series. If you missed our first article, and want to know more about the chatbot landscape and their relevance in today’s markets, you can find Part I `}<a parentName="p" {...{
        "href": "/blog/chatbots-what-why-how-pt1"
      }}>{`here`}</a>{`.`}</p>
    <p>{`In this article we’ll be diving into the inner workings of a chatbot, focusing on the Natural Language Understanding (NLU) component. For those unfamiliar with it, NLU is a subfield of Natural Language
Processing (NLP) where Artificial Intelligence (AI) is used to attempt to comprehend the meaning of written sentences or speech, and its developments have allowed chatbots to become better at understanding us. We’ll be taking a step-by-step approach on how to get started with NLU using `}<a parentName="p" {...{
        "href": "https://cloud.ibm.com/catalog/services/watson-assistant"
      }}>{`IBM’s Watson Assistant`}</a>{` to illustrate a practical example below. If you’re up for it, give it a try and tag along, there’s a free tier 😉`}</p>
    <p>{`As before, at the end of this article, there’ll also be a few insights & tips about the subjects covered…but enough of this, let’s get started! 💪`}</p>
    <hr></hr>
    <h1>{`What makes chatbots tick?`}</h1>
    <p>{`A chatbot sees a conversation as single rounds of back and forth requests & responses happening over and over. In each of these rounds, the state and context of the conversation are changing, but we can break down each round into a few main steps:`}</p>
    <ol>
      <li parentName="ol">{`Receive the user’s input`}</li>
      <li parentName="ol">{`Transform it into a standard data structure our chatbot can process (NLU)`}</li>
      <li parentName="ol">{`Decide the path and actions to take based on the conversation state, context and request (Dialog)`}</li>
      <li parentName="ol">{`Get the response back to the user`}</li>
    </ol>
    <p>{`In this article, we’ll take a closer look at the first two steps and we’ll cover the remaining steps on Part III.`}</p>
    <h2>{`Step 1: Receive the user’s input`}</h2>
    <p>{`The first thing you need to be able to do is to receive messages from your users. Depending on which channel you’re going for (Facebook Messenger, Google Actions, etc.), this might involve implementing an SDK or, in most channels, just registering a webhook to receive new message events from conversations with your chatbot. We won’t focus too much on this step as each platform has its detailed instructions on how you can set this step up, but an example of what you might get on each event originating from Facebook chat can be seen `}<a parentName="p" {...{
        "href": "here"
      }}>{`here`}</a>{`.`}</p>
    <h2>{`Step 2: NLU`}</h2>
    <p>{`Once you have access to the user’s request, you need to transform its input into something that your chatbot will understand. This is where NLU comes into play. You can use it to take the incoming message and transform it into a standard data structure that you can build a dialog tree around.`}</p>
    <p>{`The typical outcome of NLU processing is a set of `}<strong parentName="p">{`Intents`}</strong>{` and `}<strong parentName="p">{`Entities. Here’s an example`}</strong>{` :`}</p>
    <p><strong parentName="p">{`User input`}</strong>{`: “Turn off my living room lights”`}</p>
    <p>{`NLU processing outcome:`}</p>
    <p><strong parentName="p">{`Intents`}</strong>{`:
turn_off`}</p>
    <p><strong parentName="p">{`Entities`}</strong>{` :`}</p>
    <ul>
      <li parentName="ul">{`location: living room`}</li>
      <li parentName="ul">{`subject: lights`}</li>
    </ul>
    <p><strong parentName="p">{`Intents attempt to categorise the action`}</strong>{` associated with the input, while `}<strong parentName="p">{`entities allow you to get additional contextual information`}</strong>{` from the sentence. You can think of them as the whats, the hows, the whens, and so forth.`}</p>
    <p>{`Note that by default, a blank NLU model won’t be able to understand these things for any inputs, although NLU services will usually allow you to add recognition for some things by default, including common intents (greetings, goodbyes, …) and entities (dates, times, locations, numbers, …). Still, once you start building your chatbot, you’ll need to decide what you want it to be able to understand and train your
NLU model to recognise it.`}</p>
    <p>{`Let’s see how you can do that by creating an NLU model capable of understanding requests to turn things off, replicating the example above. To get started, let’s create a blank dialog skill in `}<a parentName="p" {...{
        "href": "https://cloud.ibm.com/docs/assistant?topic=assistant-getting-started"
      }}>{`Watson Assistant`}</a>{`:`}</p>
    <Image src="blog/chatbots-what-why-how-pt2/create-dialog-skill.png" alt="IBM's Watson Assistant's create dialog skill interface" caption={<a href="https://cloud.ibm.com/docs/assistant?topic=assistant-skill-add" target="_blank">
      If you need additional assistance on setting up your first skill take a look here
    </a>} mdxType="Image" />
    <p>{`To teach it how to understand an intent, we need to provide it with different examples of what people might typically say to achieve that goal. In this case, let’s create the `}<strong parentName="p">{`turn_off`}</strong>{` intent — `}<em parentName="p">{`a way of users asking to turn something off`}</em>{` — with the following examples:`}</p>
    <Image src="blog/chatbots-what-why-how-pt2/intent-example.png" alt="IBM's Watson Assistant's user intent examples" caption={<a href="https://cloud.ibm.com/docs/assistant?topic=assistant-skill-add" target="_blank">
      Additional information on adding intents to your skill
    </a>} mdxType="Image" />
    <p>{`For a production rollout we could certainly add more but for the purpose of this example, these will be enough. With the intent out of the way, we also have to teach it about the two entities we want it to look for: `}<strong parentName="p">{`locations`}</strong>{` and `}<strong parentName="p">{`subjects`}</strong>{`. These are the things we want to look out for in requests to “turn something off”. We want to know `}<em parentName="p">{`what`}</em>{` to turn off, and potentially, from `}<em parentName="p">{`which location`}</em>{`. To do this, let’s create both entities and specify the values they could take:`}</p>
    <Image src="blog/chatbots-what-why-how-pt2/intent-values.png" alt="IBM's Watson Assistant's entity intent values" caption={<a href="https://cloud.ibm.com/docs/assistant?topic=assistant-entities" target="_blank">
      Additional information on adding entities to your skill
    </a>} mdxType="Image" />
    <p>{`Note that these could take more values, but for now, we’ll assume our chatbot’s goal is just to be able to understand requests related to `}<em parentName="p">{`lights`}</em>{` in our `}<em parentName="p">{`kitchen`}</em>{` and our `}<em parentName="p">{`living room`}</em>{`. To improve their recognition, we also add a few synonyms for each value:`}</p>
    <Image src="blog/chatbots-what-why-how-pt2/intent-values.png" alt="IBM's Watson Assistant's entity synonyms" mdxType="Image" />
    <p>{`When specifying your entities, always take a few moments to think of synonyms that people might use to refer to them, as they represent a quick and easy improvement to your chatbot’s capability to understand natural requests. In this example, we’ve added `}<strong parentName="p">{`lamp`}</strong>{`, `}<strong parentName="p">{`bulb`}</strong>{` and `}<strong parentName="p">{`chandelier`}</strong>{` as synonyms that people might use to refer to when asking to turn off the lights. Note that in Watson, entities will `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Approximate_string_matching"
      }}>{`fuzzy match`}</a>{` values by default, so we don’t need to add plurals or typos for these.`}</p>
    <p>{`Ok! We’ve created the `}<em parentName="p">{`turn_off`}</em>{` intent and the two entities we needed so our NLU model should now be able to:`}</p>
    <ul>
      <li parentName="ul">{`Understand when a user asks to `}<strong parentName="li">{`turn something off`}</strong></li>
      <li parentName="ul">{`Be able to identify any `}<em parentName="li">{`subject`}</em>{` and `}<em parentName="li">{`location`}</em>{` included in those requests`}</li>
    </ul>
    <p>{`Let’s give it a go! 🤞🏻`}</p>
    <Image src="blog/chatbots-what-why-how-pt2/try-it.png" alt="IBM's Watson Assistant's try it menu" caption={<a href="https://cloud.ibm.com/docs/assistant?topic=assistant-getting-started#testing-the-start-node" target="_blank">
      Tip: try this for yourself using IBM’s “Try it” option and check your model as you go
    </a>} mdxType="Image" />
    <p>{`Success 🎉
Still, you might be thinking…`}</p>
    <blockquote>
      <p parentName="blockquote">{`“What’s so special about that? That looks just like regular string matching”`}</p>
    </blockquote>
    <p>{`The magic of NLU is that through machine learning, even if the user goes for a different utterance we can still get the same intent and entities returned to us, without ever explicitly teaching the model that sentence. Let’s try something slightly different:`}</p>
    <Image src="blog/chatbots-what-why-how-pt2/try-it-2.png" alt="IBM's Watson Assistant's try it menu" mdxType="Image" />
    <p>{`We still got the same results! This means that `}<strong parentName="p">{`from a relatively small number of examples, you can start building a chatbot that understands many of the variations`}</strong>{` we see between how each person asks for something.`}</p>
    <p>{`Try playing around with it for a while using different sentences users might use to ask to turn lights off. If you find one that the chatbot doesn’t understand, teach it by adding it as an example to the turn_off intent and try again. The more iterations you go through, the more robust your chatbot will become 🦾`}</p>
    <p>{`What happens if we ask our model about anything else though? Since we started with a blank model, most things just won’t be understood at all, but even worse, let’s see what happens if we ask it to turn the lights back on:`}</p>
    <Image src="blog/chatbots-what-why-how-pt2/try-it-3.png" alt="IBM's Watson Assistant's try it results assuming the incorrent action" mdxType="Image" />
    <p>{`That doesn’t look right 🤔
It still thinks we’re trying to turn the lights `}<strong parentName="p">{`off`}</strong>{`, even though we’re trying to turn them `}<strong parentName="p">{`on`}</strong>{`. Why is that? Our current model only knows about turning things off so to it, this request looks pretty similar to that. Let’s add a `}<strong parentName="p">{`turn_on`}</strong>{` intent as well and see if that makes a difference:`}</p>
    <Image src="blog/chatbots-what-why-how-pt2/try-it-4.png" alt="IBM's Watson Assistant's try it menu" mdxType="Image" />
    <p>{`🎉 Now that our model knows about turning things on as well, it can easily distinguish between both intents. Notice how the entities we specified are still correctly identified in these requests as well. That’s because entities aren’t really tied to intents, they will always be recognised whenever they appear in a sentence.`}</p>
    <hr></hr>
    <p>{`That’s it for Part II. We’ve covered the basics of NLU and created a Watson Assistant skill capable of understanding requests to turn things on and off. In the next and final article of this mini-series, we’ll cover the remaining two steps and show you how you to create a dialog, having your chatbot reply and perform actions based on the user’s requests. If that sounds interesting, stay tuned!`}</p>
    <p>{`As promised, before we go, here are a few insights & tips related to NLU:`}</p>
    <p>{`💡 `}<strong parentName="p">{`It’s important to understand what scope you want your chatbot to cover before deciding what you’re going to map as an intent or an entity`}</strong>{`. As we’ve just seen, this can easily make a difference in your dataset’s performance, and it will also impact its capability to evolve.`}</p>
    <p>{`💡 `}<strong parentName="p">{`The more you focus your efforts on the scope you want your chatbot to excel at, the more its users will be pleased with the experience`}</strong>{`. Just because AI is powering your chatbot’s understanding it doesn’t mean it will be able to understand and chat about everything…and that’s probably not what you want anyway!`}</p>
    <p>{`💡 `}<strong parentName="p">{`Optimising your NLU model might depend on the channel you’re aiming for`}</strong>{`. For example, voice platforms (e.g. Alexa, Google Assistant) using Speech-to-Text to generate the user’s input, will commonly include wrong words amid sentences that `}<strong parentName="p">{`sound alike`}</strong>{` to the desired word, while text first platforms (e.g. webchat, FB messenger, WhatsApp) will commonly include `}<strong parentName="p">{`typos or abbreviations`}</strong>{`.`}</p>
    <p>{`💡 `}<strong parentName="p">{`Before going live make sure you can track these and have a process in place for improving your intent categorisation`}</strong>{`. Chatbots improve over time as you train them with more examples from real conversations.`}</p>
    <p>{`Part III is available `}<a parentName="p" {...{
        "href": "/blog/chatbots-what-why-how-pt3"
      }}>{`here`}</a>{`.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      